<template>
  <div class="col-sm-12 col-xl-9 col-lg-10">
    <v-simple-table fixed-header class="mt-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left caption">Ticket #</th>
            <th class="text-left caption">Concern</th>
            <th class="text-left caption">Category</th>
            <th class="text-left caption">Priority</th>
            <th class="text-left caption">Status</th>
            <th class="text-left caption">Created</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td class="body-2">{{ item.id }}</td>
            <td class="body-2" style="width: 300px; overflow-x: hidden">
              <div style="height: 50px; overflow-y: hidden">
                <div class="body-2 pt-2" v-html="item.body" />
              </div>
            </td>
            <td class="body-2">{{ item.category }}</td>
            <td class="body-2">
              {{ $helpers.prioType(item.priority) }}
              <v-icon :color="$helpers.prioColor(item.priority)"
                >mdi-circle-medium</v-icon
              >
            </td>
            <td class="body-2">{{ $helpers.ticketStatus(item.status) }}</td>
            <td class="body-2">{{ $helpers.dateDiff(item.created_at) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>