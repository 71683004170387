<template>
  <section id="ticket">
    <v-row>
      <v-col cols="12" xl="10">
        <div class="text-h5 ml-2 mb-5">Manage Ticket</div>
        <v-tabs
          v-model="tab"
          align-with-title
          background-color="transparent"
          color="basil"
          class="mt-10"
        >
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items style="background: transparent" v-model="tab">
          <v-tab-item v-for="item in items" :key="item">
            <Form @save="snack = true" v-if="item === 'Send Ticket'" :user="user"/>
            <Table v-if="item === 'My Tickets'" :data="tickets" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <snackbar
      :snack="snack"
      text="Ticket sent"
      timeout="3000"
      @close="snack = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Form from "../components/ticket/Form.vue";
import Table from "../components/ticket/Table.vue";
export default {
  components: {
    Form,
    Table,
  },
  data: () => ({
    tab: null,
    items: ["Send Ticket", "My Tickets"],
    snack: false,
  }),
  computed: {
    ...mapState("student", {
      tickets: (state) => state.tickets,
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.getTickets();
  },
  methods: {
    ...mapActions("student", ["getTickets"]),
  },
};
</script>