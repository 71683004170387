<template>
  <section>
    <v-card max-width="700" class="pa-5 mt-5" :color="user.dark == 1 ? 'transparent' : ''">
      <v-form ref="ticket_form">
        <div class="col-sm-12 col-md-6">
          <div class="caption">PRIORITY</div>
          <v-select
            :items="$helpers.ticketPrio()"
            item-text="text"
            item-value="value"
            v-model="form.priority"
            :error-messages="errors.priority"
          />
          <div class="caption mt-2">CATEGORY</div>
          <v-select
            :items="$helpers.ticketCategory()"
            v-model="form.category"
            :error-messages="errors.category"
          />
        </div>
        <vue-editor v-model="form.body" />
        <v-btn @click="send" color="warning" class="mt-5">Submit</v-btn>
      </v-form>
    </v-card>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: ["user"],
  data: () => ({
    form: {
      category: "",
      priority: "",
      body: "",
    },
    errors: [],
  }),
  computed: {
    ...mapState("student", {
      ticket_errors: (state) => state.errors,
    }),
  },
  methods: {
    ...mapActions("student", ["createTicket"]),

    send() {
      this.createTicket(this.form).then(() => {
        if (this.$errors("student")) {
          this.errors = this.ticket_errors;
          return;
        }
        this.errors = [];
        this.$refs.ticket_form.reset();
        this.form.body = "";
        this.$emit("save");
      });
    },
  },
};
</script>
